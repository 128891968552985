<template>
  <v-row no-gutters>
    <v-col cols="3" />
    <v-col cols="6">
      <h2 class="red--text text-center font-weight-light">403</h2>
      <h1 class="red--text font-weight-thin text-center">Access Denied</h1>
      <v-divider />
      <div class="text-center grey--text">
        {{ quotes[Math.floor(Math.random() * quotes.length)] }}
      </div>
    </v-col>
    <v-col cols="3" />
  </v-row>
</template>
<script>
export default {
  data: () => ({
    quotes: [
      "And, for an instant, she stared directly into those soft blue eyes and knew, with an instinctive mammalian certainty, that the exceedingly rich were no longer even remotely human.",
      "Time moves in one direction, memory in another.",
      "The 'Net is a waste of time, and that's exactly what's right about it.",
      "The box was a universe, a poem, frozen on the boundaries of human experience.",
      "The sky above the port was the color of television, tuned to a dead station.",
    ],
  }),
};
</script>
